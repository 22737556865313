<template>
    <div class="details">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>部门</span>
                    <el-select
                        v-model="searchVal.dempVal"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择部门"
                        @change="
                            (currentPage = 1),
                                (pagesize = 20),
                                changeDepartmentId()
                        "
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span>成员</span>
                    <el-select
                        v-model="searchVal.adminVal"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择成员"
                        @change="
                            (currentPage = 1), (pagesize = 20), getData(isStsts)
                        "
                    >
                        <el-option
                            v-for="item in userData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 19px;
                            background: #fff;
                            border-color: ##2370eb;
                            color: #2370eb;
                        "
                        size="mini"
                        @click="goBack"
                    >
                        <i class="el-icon-arrow-left"></i>返回列表
                    </el-button>
                </p>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(2)"
                    :class="isStsts == 2 ? 'isStatusColor' : ''"
                    >全部</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(1)"
                    :class="isStsts == 1 ? 'isStatusColor' : ''"
                    >待发送</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(3)"
                    :class="isStsts == 3 ? 'isStatusColor' : ''"
                    >待确认</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(4)"
                    :class="isStsts == 4 ? 'isStatusColor' : ''"
                    >已确认</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(5)"
                    :class="isStsts == 5 ? 'isStatusColor' : ''"
                    >已反馈
                </span>
                <span
                    @click="(pagesize = 20), (currentPage = 1), getData(6)"
                    :class="isStsts == 6 ? 'isStatusColor' : ''"
                    >已撤回</span
                >
            </div>
            <p>
                <el-button
                    @click="onDialog(1)"
                    size="mini "
                    type="primary"
                    style="
                        cursor: pointer;
                        background: #2370eb;
                        color: #fff;
                        border-color: #2370eb;
                    "
                >
                    全部撤回
                </el-button>
                <el-button
                    @click="onDialog(2)"
                    size="mini "
                    type="primary"
                    style="
                        cursor: pointer;
                        background: #2370eb;
                        color: #fff;
                        border-color: #2370eb;
                    "
                >
                    全部重发
                </el-button>
            </p>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column label="年/月" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.month || '- -' }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="adminName" label="员工"> </el-table-column>
            <el-table-column
                label="部门"
                prop="departmentName"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="实发工资" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ getAmount(scope.row) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '待发送'
                            : scope.row.status == 2
                            ? '待查看'
                            : scope.row.status == 3
                            ? '待确认'
                            : scope.row.status == 4
                            ? '已确认'
                            : scope.row.status == 5
                            ? '已反馈'
                            : scope.row.status == 6
                            ? '已撤回'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="问题反馈" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.wagesDetailFeedbackBOList &&
                            scope.row.wagesDetailFeedbackBOList.length > 0
                        "
                    >
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            size="small"
                            @click="onFeedBack(scope.row)"
                            >查看</el-button
                        >
                    </span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column label="发送时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.sendTime || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        size="small"
                        @click="onDetailed(scope.row)"
                        >明细</el-button
                    >
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        size="small"
                        v-if="scope.row.status != 6 && scope.row.status != 1"
                        @click="onDialog(3, scope.row)"
                        >撤回</el-button
                    >
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        size="small"
                        v-if="scope.row.status == 1"
                        @click="onDialog(4, scope.row)"
                        >发送</el-button
                    >
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        size="small"
                        v-if="scope.row.status == 6"
                        @click="onDialog(4, scope.row)"
                        >重发</el-button
                    >
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 明细 -->
        <el-drawer
            :visible.sync="drawerDetailed"
            :direction="direction"
            :before-close="handleDetailed"
            size="720px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    工资条明细
                </div>
            </template>
            <Detailed ref="detailed" @close="handleDetailed" />
        </el-drawer>
        <!-- 删除/发送 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ dialogTitle }}
                </div>
            </template>
            <div class="dialog_info">
                <p>
                    <i
                        class="el-icon-warning-outline"
                        style="
                            color: #f5a623;
                            font-size: 50px;
                            margin-bottom: 15px;
                        "
                    ></i>
                </p>

                <p>{{ dialogText }}</p>
            </div>
            <div slot="footer" class="bottom-but">
                <el-button
                    @click="handleClose"
                    round
                    style="padding: 7px 20px"
                    size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="
                        margin-right: 15px;
                        background: #2370eb;
                        padding: 7px 20px;
                    "
                    round
                    @click="onSubmit()"
                    size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 问题反馈 -->
        <el-dialog
            :visible.sync="dialogFeedback"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    问题反馈
                </div>
            </template>
            <Feedback ref="feedback"></Feedback>
        </el-dialog>
    </div>
</template>

<script>
import Detailed from './detailed.vue';
import Feedback from './feedback.vue';
import {
    wagesDetailList,
    wagesAllRemove,
    wagesSend,
    wagesDetailUpdate,
} from '@/api/wages/wages';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    components: {
        Detailed,
        Feedback,
    },
    data() {
        return {
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerDetailed: false,
            direction: 'rtl',
            isStsts: 2,
            dialog: false,
            dialogText: '',
            dialogTitle: '',
            dialogType: 0,
            dialogData: {},
            dialogFeedback: false,
            searchVal: {},
            dempData: [],
            userData: [],
        };
    },
    mounted() {
        this.getDempData();

        this.getData(this.isStsts);
    },
    methods: {
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
            });
        },

        changeDepartmentId() {
            this.searchVal.adminVal = '';
            this.getData(this.isStsts);
            this.getUserList(this.searchVal.dempVal);
        },
        getUserList(departmentId) {
            let data = {
                param: { departmentId },
            };

            roleList(data).then((res) => {
                this.userData = res.data;
            });
        },
        getData(i) {
            this.isStsts = i;
            let data = {
                param: {
                    wagesId: this.$route.query.id,
                    departmentId: this.searchVal.dempVal,
                    adminId: this.searchVal.adminVal,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (i != 2) {
                data.param.status = i;
            }
            wagesDetailList(data).then((res) => {
                console.log(res);
                this.tableData = res.data.list;

                this.total = res.data.total;
            });
        },
        getAmount(row) {
            let nub = 0;
            nub += row.baseTotalAmount;
            nub += row.workTotalAmount;
            nub += row.commissionTotalAmount;
            nub += row.otherTotalAmount;
            nub -= row.leaveTotalAmount;
            nub -= row.taxTotalAmount;
            return nub.toFixed(2);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getdata(this.isStsts);
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getdata(this.isStsts);
        },
        // 反馈
        onFeedBack(row) {
            this.dialogFeedback = true;
            setTimeout(() => {
                this.$refs.feedback.getData(row);
            }, 0);
        },

        // 明细
        onDetailed(row) {
            this.drawerDetailed = true;
            setTimeout(() => {
                this.$refs.detailed.getData(row);
            });
        },
        handleDetailed() {
            this.drawerDetailed = false;
            this.getData(this.isStsts);
        },
        onDialog(i, row) {
            if (row) {
                this.dialogData = row;
            }
            this.dialogType = i;
            if (i == 1) {
                this.dialogText = '即将撤回所有工资表，是否确认';
                this.dialogTitle = '撤回工资表';
            }
            if (i == 2) {
                this.dialogText = '即将发送所有工资表，是否确认？';
                this.dialogTitle = '发送工资表';
            }
            if (i == 3) {
                this.dialogText = '即将撤回该条工资，是否确认？';
                this.dialogTitle = '撤回工资';
            }
            if (i == 4) {
                this.dialogText = '即将发送该该条工资，是否确认？';
                this.dialogTitle = '发送工资';
            }

            this.dialog = true;
        },

        onSubmit() {
            let data = {
                param: {
                    wagesId: this.$route.query.id,
                },
            };
            if (this.dialogType == 1) {
                wagesAllRemove(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
            if (this.dialogType == 2) {
                wagesSend(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
            if (this.dialogType == 3 || this.dialogType == 4) {
                let obj = {
                    param: {
                        id: this.dialogData.id,
                    },
                };
                if (this.dialogType == 3) {
                    obj.param.status = 6;
                }
                if (this.dialogType == 4) {
                    obj.param.status = 2;
                }
                wagesDetailUpdate(obj).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                        if (this.dialogType == 3) {
                            this.onDetailed(this.dialogData);
                        }
                    }
                });
            }
        },
        handleClose() {
            this.dialog = false;
            this.dialogFeedback = false;
            this.getData(this.isStsts);
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }

    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.status_btn {
    span {
        display: inline-block;
        border: 1px solid #f0f0f0;
        border-radius: 13px;
        padding: 4px 12px;
        font-size: 13px;
        color: #666666;
        margin-right: 12px;
        cursor: pointer;
        position: relative;
    }
    .isStatusColor {
        background: #e9f2ff;
        color: #2370eb;
        border-color: #e9f2ff;
    }
    .toast_gif {
        position: absolute;
        font-size: 12px;
        width: 16px;
        height: 16px;
        border-radius: 100px;
        color: #fff;
        text-align: center;
        line-height: 16px;
        font-weight: 560;
        right: -5px;
        top: -5px;
    }
}
.dialog_info {
    width: 80%;
    text-align: center;
    margin: 25px auto 80px;
    font-size: 14px;
    color: #333333;

    p + p {
        margin-top: 16px;
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/ .el-dialog__footer {
    padding: 0;
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
</style>
