<template>
    <div class="detailed">
        <div class="user_text">
            <p>
                <span
                    class="iconfont icon-gerenziliao"
                    style="
                        margin: 0 16px 0 24px;
                        color: #2370eb;
                        font-size: 20px;
                    "
                ></span>
                <span>员工：</span>
                {{ data.adminName }}
            </p>
            <p>
                <span>所属部门：</span>

                <el-tooltip placement="top"
                    >·
                    <div slot="content">
                        <p
                            style="line-height: 2"
                            v-for="item in data.departmentAdminDOList"
                            :key="item.id"
                        >
                            {{ item.departmentName }}
                        </p>
                    </div>
                    <em style="font-style: normal">
                        {{ returnName(data) }}
                    </em>
                </el-tooltip>
            </p>
        </div>

        <div class="basic_wages">基本工资</div>

        <div class="basic_wages_constitute">
            <p>
                <span>基本薪资</span>
                <span>
                    <el-input
                        v-model.number="data.bottomAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                    ></el-input>
                </span>
            </p>
            <p>
                <span>岗位工资</span>
                <span>
                    <el-input
                        v-model.number="data.postAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                    ></el-input>
                </span>
            </p>
            <p>
                <span>职级薪资</span>
                <span>
                    <el-input
                        v-model.number="data.rankAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                    ></el-input>
                </span>
            </p>
            <p>
                <span>绩效薪资</span>
                <span>
                    <el-input
                        v-model.number="data.meritsAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                    ></el-input>
                </span>
            </p>
            <p>
                <span>全勤</span>
                <span>
                    <el-input
                        v-model.number="data.fullAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                    ></el-input>
                </span>
            </p>
            <p>
                <span>基本工资</span>
                <span>
                    <el-input
                        v-model.number="data.baseAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                    ></el-input>
                </span>
            </p>
            <p>
                <span>小计</span>
                <span>
                    <el-input
                        v-model.number="data.baseTotalAmount"
                        size="medium"
                        oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                        :disabled="disabled"
                        @change="getStatistics()"
                    ></el-input>
                </span>
            </p>
        </div>
        <div class="work_overtime">
            <div>
                <div>加班栏</div>
                <div>
                    <p>
                        <span>加班餐补</span>
                        <span>
                            <el-input
                                v-model.number="data.workAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>加班天数</span>
                        <span>
                            <el-input
                                v-model.number="data.workDay"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>
                            <el-input
                                v-model.number="data.workTotalAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                                @change="getStatistics()"
                            ></el-input>
                        </span>
                    </p>
                </div>
            </div>
            <div>
                <div>提成</div>
                <div>
                    <p>
                        <span>个人提成</span>
                        <span>
                            <el-input
                                v-model.number="data.personalAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>管理提成</span>
                        <span>
                            <el-input
                                v-model.number="data.manageAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>专项奖金</span>
                        <span>
                            <el-input
                                v-model.number="data.specialAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>
                            <el-input
                                v-model.number="data.commissionTotalAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                                @change="getStatistics()"
                            ></el-input>
                        </span>
                    </p>
                </div>
            </div>
            <div class="other">
                <div>其他</div>
                <div>
                    <span>
                        <el-input
                            v-model.number="data.otherTotalAmount"
                            size="medium"
                            oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                            :disabled="disabled"
                            @change="getStatistics()"
                        ></el-input>
                    </span>
                </div>
            </div>
        </div>

        <div class="vacation">
            <div>
                <div>假扣</div>
                <div>
                    <p>
                        <span>病假(天)</span>
                        <span>
                            <el-input
                                v-model.number="data.sickAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>事假(天)</span>
                        <span>
                            <el-input
                                v-model.number="data.matterAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>矿工(天)</span>
                        <span>
                            <el-input
                                v-model.number="data.absenteeismAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>
                            <el-input
                                v-model.number="data.leaveTotalAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                                @change="getStatistics()"
                            ></el-input>
                        </span>
                    </p>
                </div>
            </div>
            <div>
                <div>代扣代缴</div>
                <div>
                    <p>
                        <span>社保</span>
                        <span>
                            <el-input
                                v-model.number="data.socialAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>公积金</span>
                        <span>
                            <el-input
                                v-model.number="data.reserveAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>个税</span>
                        <span>
                            <el-input
                                v-model.number="data.taxAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                            ></el-input>
                        </span>
                    </p>
                    <p>
                        <span>小计</span>
                        <span>
                            <el-input
                                v-model.number="data.taxTotalAmount"
                                size="medium"
                                oninput="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
                                :disabled="disabled"
                                @change="getStatistics()"
                            ></el-input>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div class="last_div">
            应发合计
            <span>{{ statistics.should }}</span>
        </div>
        <div class="last_div">
            代扣合计
            <span>{{ statistics.withhold }}</span>
        </div>
        <div class="last_div">
            实发工资
            <span>{{ statistics.actual }}</span>
        </div>

        <div class="bottom_btn">
            <el-button
                round
                size="mini"
                style="padding: 8px 25px"
                @click="onClose()"
                >取 消</el-button
            >

            <el-button
                type="primary"
                style="
                    margin-right: 16px;
                    background-color: #2370eb;
                    padding: 8px 25px;
                "
                round
                size="mini"
                @click="onUpDate()"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
import { wagesDetailGet, wagesDetailUpdate } from '@/api/wages/wages';
export default {
    data() {
        return {
            disabled: true,
            data: {},
            input: '',
            statistics: {
                should: 0,
                withhold: 0,
                actual: 0,
            },
        };
    },
    methods: {
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            wagesDetailGet(data).then((res) => {
                this.data = res.data;
                if (res.data.status == 1 || res.data.status == 6) {
                    this.disabled = false;
                } else {
                    this.disabled = true;
                }
                let nub = 0;
                nub += res.data.baseTotalAmount;
                nub += res.data.workTotalAmount;
                nub += res.data.commissionTotalAmount;
                nub += res.data.otherTotalAmount;
                this.statistics.withhold = res.data.taxTotalAmount.toFixed(2);
                this.statistics.should = (
                    nub - this.data.leaveTotalAmount
                ).toFixed(2);
                this.statistics.actual = (
                    nub -
                    res.data.taxTotalAmount -
                    res.data.leaveTotalAmount
                ).toFixed(2);
            });
        },
        getStatistics() {
            let nub = 0;
            nub += Number(this.data.baseTotalAmount);
            nub += this.data.workTotalAmount;
            nub += this.data.commissionTotalAmount;
            nub += this.data.otherTotalAmount;
            this.statistics.withhold = this.data.taxTotalAmount.toFixed(2);
            this.statistics.should = (nub - this.data.leaveTotalAmount).toFixed(
                2
            );
            this.statistics.actual = (
                nub -
                this.data.taxTotalAmount -
                this.data.leaveTotalAmount
            ).toFixed(2);
        },
        onUpDate() {
            let data = {
                param: this.data,
            };
            wagesDetailUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.el-icon-warning-outline {
    color: #f5a623;
}
.detailed {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 9px;
    .user_text {
        display: flex;
        align-items: center;
        p {
            margin-right: 45px;
            font-size: 14px;
            color: #333333;
            padding: 16px 0;
            display: flex;
            align-items: center;
        }
    }
    > p {
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px dashed #f5a623;
        font-size: 14px;
        margin: 0px 12px 9px;
        span {
            color: #f5a623;
        }
    }
    .basic_wages {
        height: 48px;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #eeeeee;
        text-align: center;
        line-height: 48px;
        font-weight: 600;
        font-size: 13px;
    }
    .basic_wages_constitute {
        display: flex;
        p {
            flex: 1;
            text-align: center;
            span {
                display: block;
                border-bottom: 1px solid #eeeeee;
                padding: 0;
                font-size: 13px;
                color: #333;
            }
            span:first-child {
                color: #666;
                padding: 10px 0;
            }
        }
    }
    .work_overtime {
        border-radius: 4px 4px 0px 0px;
        display: flex;
        margin-top: 16px;
        font-size: 13px;
        > div:first-child {
            width: 195px;
        }
        > div:nth-child(2) {
            flex: 1;
        }
        > div {
            text-align: center;
            box-sizing: border-box;

            border-right: 1px solid #e5e5e5;
            > div:first-child {
                line-height: 48px;
                height: 48px;
                background: #ebf3ff;
                font-weight: 600;
                border-bottom: 1px solid #eeeeee;
            }
            > div:last-child {
                display: flex;
                p {
                    flex: 1;
                    text-align: center;
                    span {
                        display: block;
                        border-bottom: 1px solid #eeeeee;
                        font-size: 13px;
                        color: #333;
                    }
                    span:first-child {
                        color: #666;
                        padding: 10px 0;
                    }
                }
            }
        }
        > div:last-child {
            width: 85px;
        }
        .other {
            > div:last-child {
                display: block;
                height: calc(100% - 50px);
                line-height: 74px;
                border-bottom: 1px solid #eee;
            }
        }
    }
    .vacation {
        display: flex;
        margin-top: 16px;
        margin-bottom: 18px;
        > div:first-child {
            border-right: 1px solid #eee;
        }
        > div {
            flex: 1;
            font-size: 14px;
            > div:first-child {
                height: 48px;
                background: #fff9f0;
                border-radius: 4px 4px 0px 0px;
                text-align: center;
                line-height: 48px;
                font-weight: 600;
                border-bottom: 1px solid #eeeeee;
            }
            > div:last-child {
                display: flex;
                p {
                    flex: 1;
                    text-align: center;
                    span {
                        display: block;
                        border-bottom: 1px solid #eeeeee;

                        font-size: 13px;
                        color: #333;
                    }
                    span:first-child {
                        color: #666;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
    .last_div {
        margin: 0 12px;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 600;
        background: #f9fafc;
        border-radius: 8px;
        span {
            float: right;
        }
    }
    .last_div + .last_div {
        margin-top: 6px;
    }

    .bottom_btn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
    }
}
</style>
<style>
.detailed .el-input__inner {
    border: none !important;
    text-align: center;
    padding: 0 !important;
}
</style>
